import $ from "jquery";
import {CloseBatchLayer} from "./CloseBatchLayer";

import {
    B64File,
    CountriesOfBatch,
    EffectiveProgressState,
    GenerateCretentialBatches,
    getDefaultRequestOptions,
    GetReport,
    inputGetUpdates,
    PrintLettersBatch,
    ProgressPhase,
    ServerProgressStateType,
    Update,
} from "../Api/Types";
import {DataLayer} from "./DataLayer";

export class PrintLettersLayer extends CloseBatchLayer {
    private batches: PrintLettersBatch[];
    private filteredBatch: PrintLettersBatch[];
    private selectedCountriesByBatch: { [batchId: number]: number[] } = {}; // Object to store selected countries by batch
    private state: EffectiveProgressState;
    private selected_batches: boolean = false;

    private reattach() {
        this.client.begin_reattach().done(x => {
            console.log(x);
            this.state = x.effective_state;
            this.attachEventListeners();
            this.updateNextStepButtonState()
            this.draw_page(this.state.phase)
        })
    }

    private attachEventListeners() {

        $('#prevStep').off('click').on('click', () => {
            this.prevStep();
        });

        $('#selectAll').off('change').on('change', (event) => {
            const isChecked = $(event.target).prop('checked');
            this.toggleSelectAll(isChecked);
        });

        $('#print-letters-select-baches-table-body').off('change', 'input[type="checkbox"]').on('change', 'input[type="checkbox"]', () => {
            this.updateNextStepButtonState();
        });

        $('#selectAll-languages').off('change').on('change', (event) => {
            const isChecked = $(event.target).prop('checked');
            this.toggleSelectAllNations(isChecked);
        });

        $('#nextStep').off('click').on('click', () => {
            this.nextStep();
        });

        $('#resetAll').off('click').on('click', () => {
            this.resetAll();
        });

        $('#download').on('click', () => {
            this.client.download_zip().done((x: B64File) => {
                this.HandleDownload(x);
            });
        });

        $('#redo-generate').on('click', () => {
            this.draw_page(ProgressPhase.SELECT_BATCHES_NATIONS);
        });

        $('#close-batches').on('click', () => {
            this.onClosePrintingBatch();
        });
    }

    private resetCheckBoxes() {
        this.selected_batches = false;
        $('#select-all-batches-all-languages').prop('checked', false).trigger('change');
        $('#selectAll').prop('checked', false).trigger('change');
    }

    private prevStep() {
        this.resetCheckBoxes();
        this.draw_page(this.state.phase);
    }

    private nextStep(phase: ProgressPhase = this.state.phase) {
        switch (phase) {
            case ProgressPhase.SELECT_BATCHES_NATIONS:
                if ((!this.selected_batches) && (!this.isAnyLanguageSelected())) {
                    this.selected_batches = true;
                    this.showStep(this.state.phase)
                    this.draw_page(this.state.phase)
                } else {
                    this.draw_page(ProgressPhase.GENERATE_CREDENTIALS)
                }
                break;
            case ProgressPhase.GENERATE_CREDENTIALS_DISPLAY_START:
                this.draw_page(ProgressPhase.GENERATE_CREDENTIALS);
                break;
            case ProgressPhase.GENERATE_CREDENTIALS:
                this.draw_page(ProgressPhase.GENERATE_CREDENTIALS)
                break;
            case ProgressPhase.GENERATE_PDF:
                this.draw_page(ProgressPhase.GENERATE_PDF)
                break;
            case ProgressPhase.SUMMARY:
                this.draw_page(ProgressPhase.SUMMARY)
                break;
            default:
                break;
        }
    }

    private resetAll() {
        this.resetCheckBoxes();
        this.toggleButtons();
        this.client.reset_all().done(() => {
            setTimeout(() => {
                document.location.reload();
            }, 100);
        });
    }

    private toggleButtons(phase: ProgressPhase = this.state.phase, enable: boolean = true): void {
        const nextStepButton = $('#nextStep');
        const prevStepButton = $('#prevStep');
        const retryButton = $('#resetAll');

        /** hande visibility of buttons */

        if (phase === ProgressPhase.SELECT_BATCHES_NATIONS) {
            if (!this.selected_batches) {
                prevStepButton.css('display', 'none');
                retryButton.css('display', 'none');
            } else {
                prevStepButton.css('display', '');
                retryButton.css('display', 'none');
            }
        } else if (phase === ProgressPhase.GENERATE_CREDENTIALS_DISPLAY_START || phase === ProgressPhase.GENERATE_CREDENTIALS || phase === ProgressPhase.GENERATE_PDF) {
            prevStepButton.css('display', 'none');
            retryButton.css('display', '');
        } else if (phase === ProgressPhase.SUMMARY) {
            prevStepButton.css('display', 'none');
            retryButton.css('display', 'none');
            nextStepButton.css('display', 'none');
        } else {
            prevStepButton.css('display', '');
            retryButton.css('display', 'none');
        }

        if (enable) {
            nextStepButton.prop('disabled', false).css(this.enable_next_css);
            prevStepButton.prop('disabled', false).css(this.enable_back_css);
            retryButton.prop('disabled', false).css(this.enable_back_css);
        } else {
            nextStepButton.prop('disabled', true).css(this.disable_css);
            prevStepButton.prop('disabled', true).css(this.disable_css);
            retryButton.prop('disabled', true).css(this.disable_css);
        }
    }

    private toggleSelectAllNations(checked: boolean): void {
        // Ensure all language checkboxes are correctly targeted
        const checkboxes = $('#print-letters-nation-selection-table-body').find('input[name="lang"]');
        checkboxes.prop('checked', checked);
        // Update the Next Step button state after toggling checkboxes
        this.updateNextStepButtonState();
    }

    private toggleSelectAll(checked: boolean): void {
        // Ensure all checkboxes have the name "batch" for consistency
        const checkboxes = $('#print-letters-select-baches-table-body').find('input[type="checkbox"]');
        checkboxes.prop('checked', checked);
        // Update the Next Step button state after toggling checkboxes
        this.updateNextStepButtonState();
    }

    private updateNextStepButtonState(): void {
        // Check if any batch is selected in step 1
        const nextStepButton = $('#nextStep');

        if (this.state.phase === ProgressPhase.SELECT_BATCHES_NATIONS) {
            // Check if any batch is selected in step 1
            if (!this.selected_batches) {
                const anyBatchSelected = $('#print-letters-select-baches-table-body').find('input[type="checkbox"]:checked').length > 0;
                if (anyBatchSelected) {
                    nextStepButton.css(this.enable_next_css).prop('disabled', false);
                } else {
                    nextStepButton.css(this.disable_css).prop('disabled', true);
                }
            } else {
                // Check if any language is selected in step 2
                const anyLanguageSelected = this.isAnyLanguageSelected();
                if (anyLanguageSelected) {
                    nextStepButton.css(this.enable_next_css).prop('disabled', false);
                } else {
                    nextStepButton.css(this.disable_css).prop('disabled', true);
                }
            }
        } else {
            // Enable the button for other steps by default
            nextStepButton.css(this.enable_next_css).prop('disabled', false);
        }
    }

    private isAnyLanguageSelected(): boolean {
        // Find any checked language checkboxes inside the container for nations
        return $('#language-container').find('input[name="lang"]:checked').length > 0;
    }

    private selectBatchAndNation() {
        const printLettersTBody = $('#print-letters-select-baches-table-body');

        // Clear previous content
        printLettersTBody.empty();

        for (const batch of this.batches) {
            const row = $(DataLayer.templates["print-letters-row-template"]);

            const checkbox = $('<input>').attr({
                type: 'checkbox',
                name: 'batch',
                id: batch.batch_id,
                checked: false,
                // 'upload-date': batch.upload_date,
                // 'items': batch.items
            });
            for (const country of batch.nations) {

                checkbox.data("lang", country.nation);
                checkbox.data("upload-date", batch.upload_date);
                checkbox.data("items", batch.items);
                row.find('[data-content-type="print-letters-table-row-selected"]').append(checkbox);
                row.find('[data-content-type="print-letters-table-row-name"]').text(batch.name);
                row.find('[data-content-type="print-letters-table-row-date"]').text(this.formatDateData(batch.upload_date));
                row.find('[data-content-type="print-letters-table-row-items"]').text(batch.items);

                printLettersTBody.append(row);
            }
        }
    }

    private showLanguageSelector() {
        $('#prevStep').css('display', '');
        $('#nextStep').prop('disabled', true).css(this.disable_css);
        this.updateNextStepButtonState();

        const selected = $('#print-letters-select-baches-table-body').find('input[type="checkbox"]:checked').map((index, element) => {
            const checkbox = $(element);
            return Number(checkbox.prop('id'));
        }).get();

        const batchContainer = $('#batch-container');
        const languageContainer = $('#language-container');
        batchContainer.empty();
        languageContainer.empty();

        const filteredBatch = this.batches.filter(x => selected.includes(x.batch_id));
        this.filteredBatch = filteredBatch;

        const updateBatchSelectAllCheckbox = (batch: PrintLettersBatch) => {
            const batchSelectAllCheckbox = $(`#select-all-${batch.name}`);
            const selectedCountries = this.selectedCountriesByBatch[batch.batch_id] || [];
            const allCountriesSelected = batch.nations.every(country => selectedCountries.includes(country.nation_id));
            batchSelectAllCheckbox.prop('checked', allCountriesSelected);
        };

        const updateGlobalSelectAllCheckbox = () => {
            const allBatchesSelected = batchContainer.find('input[name="all-batch-selector"]').length === batchContainer.find('input[name="all-batch-selector"]:checked').length;
            $('#select-all-batches-all-languages').prop('checked', allBatchesSelected);
        };

        const displayNations = (batch: PrintLettersBatch) => {
            languageContainer.empty();

            const selectedCountries = this.selectedCountriesByBatch[batch.batch_id] || [];

            const sortedCountries = batch.nations.sort((a, b) => a.nation.localeCompare(b.nation));

            sortedCountries.forEach(country => {
                const rowDiv = $('<div>').addClass('row-container');
                const checkbox = $('<input>').attr({
                    type: 'checkbox',
                    name: 'lang',
                    id: country.nation_id,
                    class: 'little-margin',
                    checked: selectedCountries.includes(country.nation_id) // Preserve selection state
                });

                const countDiv = $('<span>').addClass('count').text(country.items);
                const label = $('<label>').attr({
                    for: country.nation_id,
                    class: 'little-margin',
                }).text(country.nation);

                rowDiv.append(checkbox, countDiv, label);
                languageContainer.append(rowDiv);

                checkbox.on('change', () => {
                    const isChecked = checkbox.prop('checked');
                    const countryCode = Number(checkbox.attr('id'));

                    // Update the internal data for the batch
                    if (isChecked) {
                        if (!selectedCountries.includes(countryCode)) {
                            selectedCountries.push(countryCode);
                        }
                    } else {
                        const index = selectedCountries.indexOf(countryCode);
                        if (index !== -1) {
                            selectedCountries.splice(index, 1);
                        }
                    }

                    this.selectedCountriesByBatch[batch.batch_id] = selectedCountries;

                    // Update the batch-level select-all checkbox and global select-all checkbox
                    updateBatchSelectAllCheckbox(batch);
                    updateGlobalSelectAllCheckbox();
                    this.updateNextStepButtonState();
                });
            });

            // Initialize batch-level select-all checkbox
            updateBatchSelectAllCheckbox(batch);
        };

        const handleBatchSelection = (batchDiv: JQuery<HTMLElement>, batch: PrintLettersBatch) => {
            batchContainer.find('.batch-item').removeClass('selected');
            batchDiv.addClass('selected');

            // Display nations for the selected batch
            displayNations(batch);
        };

        const handleSelectAllBatch = (selectAllCheckbox: JQuery<HTMLElement>, batch: PrintLettersBatch) => {
            const isChecked = selectAllCheckbox.prop('checked');
            const selectedCountries = this.selectedCountriesByBatch[batch.batch_id] || [];

            // Ensure that all countries of the specific batch are selected/deselected
            batch.nations.forEach(nation => {
                const countryCode = nation.nation_id;
                if (isChecked) {
                    if (!selectedCountries.includes(countryCode)) {
                        selectedCountries.push(countryCode);
                    }
                } else {
                    const index = selectedCountries.indexOf(countryCode);
                    if (index > -1) {
                        selectedCountries.splice(index, 1);
                    }
                }
            });

            this.selectedCountriesByBatch[batch.batch_id] = selectedCountries;

            // Only update the UI if the selected batch is currently displayed
            const selectedBatchId = batchContainer.find('.batch-item.selected').data('batch-id');
            if (selectedBatchId === batch.batch_id) {
                displayNations(batch); // Re-render the nations UI for the selected batch
            }

            // Update the batch-level select-all checkbox and the global select-all checkbox
            updateBatchSelectAllCheckbox(batch);

            // Update the global select-all checkbox
            updateGlobalSelectAllCheckbox();

            this.updateNextStepButtonState();
        };

        filteredBatch.forEach((batch, index) => {
            const batchDiv = $('<div>').addClass('batch-item').attr('data-batch-id', batch.batch_id); // Add data-batch-id attribute

            const selectAllBatch = $('<input>').attr({
                type: 'checkbox',
                name: 'all-batch-selector',
                id: `select-all-${batch.name}`,
                class: 'check-box',
            });

            batchDiv.append(selectAllBatch);
            batchDiv.append(batch.name);
            batchContainer.append(batchDiv);

            batchDiv.on('click', (event) => {
                if (!$(event.target).is('input')) {
                    handleBatchSelection(batchDiv, batch);
                }
            });

            selectAllBatch.on('change', () => {
                handleSelectAllBatch(selectAllBatch, batch);
            });

            if (index === 0) {
                handleBatchSelection(batchDiv, batch);
            }
        });

        $('#select-all-batches-all-languages').on('change', (event) => {
            const isChecked = $(event.target).prop('checked');
            $('input[name="all-batch-selector"]').prop('checked', isChecked).trigger('change');
            this.updateNextStepButtonState();
        });

        // Update all batch select-all checkboxes and the global select-all checkbox when a specific nation checkbox changes
        batchContainer.on('change', 'input[name="lang"]', () => {
            batchContainer.find('input[name="all-batch-selector"]').each((index, checkbox) => {
                const batchId = Number($(checkbox).attr('id').replace('select-all-', ''));
                const batch = this.batches.find(b => b.batch_id === batchId);
                if (batch) {
                    updateBatchSelectAllCheckbox(batch);
                }
            });
            updateGlobalSelectAllCheckbox();
        });
    }

    private GenerateCredentialsStartButtonDisplay() {
        this.client.get_selected_batches().done((x) => {
            this.filteredBatch = x;
            if (this.filteredBatch.length > 0) {
                this.toggleButtons(ProgressPhase.GENERATE_CREDENTIALS, true);

            } else this.resetAll();
        })
    }

    private GenerateCredentials() {

        if (!this.filteredBatch) this.client.get_selected_batches().done((x) => {
            this.filteredBatch = x;
        });

        const progressContainer = $('#batch-container-step-3');
        $('#step-3-description').html('<span class="description">Generating access credentials for the payment portal...</span>');
        const languageContainer = $('#nation-container-step-3');

        // Disable buttons at the start
        this.toggleButtons(ProgressPhase.GENERATE_CREDENTIALS, true);

        // Initialize a progress tracking object
        const batchProgressMap: { [batchId: string]: { totalItems: number, processedItems: number } } = {};

        // Helper to update UI elements for batch progress
        const updateBatchCounter = (batchId: number) => {
            const batchData = batchProgressMap[batchId] || {totalItems: 0, processedItems: 0};
            const rowDiv = progressContainer.find(`#${batchId}`);
            const batchProgressBarContainer = rowDiv.find('.loading-bar-container');
            const progressBar = batchProgressBarContainer.find('.progress');
            const progressCounter = batchProgressBarContainer.find('.progress-counter');

            const batchProgress = batchData.totalItems > 0 ? (batchData.processedItems / batchData.totalItems) * 100 : 0;
            progressBar.css('width', `${Math.min(batchProgress, 100)}%`).text(`${Math.round(batchProgress)}%`);
            progressCounter.text(`${Math.round(batchData.processedItems)}/${batchData.totalItems}`);
        };

        const updateBatchProgress = () => {
            let totalBatches = this.filteredBatch.length;
            let completedBatches = 0;
            let totalItems = 0;
            let totalProcessedItems = 0;

            // Loop through each batch to update its progress
            progressContainer.find('.row-container').each((index, element) => {
                const rowDiv = $(element);
                const batchId = rowDiv.prop('id') as string;
                const batchData = batchProgressMap[batchId] || {totalItems: 0, processedItems: 0};

                totalItems += batchData.totalItems;
                totalProcessedItems += batchData.processedItems;

                const batchProgress = batchData.totalItems > 0 ? (batchData.processedItems / batchData.totalItems) * 100 : 0;
                const cappedBatchProgress = Math.min(batchProgress, 100);

                rowDiv.find('.progress').css('width', `${cappedBatchProgress}%`).text(`${Math.round(cappedBatchProgress)}%`);

                // Check if the batch is complete
                if (cappedBatchProgress === 100) {
                    completedBatches += 1;
                }
            });

            const overallProgress = totalItems > 0 ? (totalProcessedItems / totalItems) * 100 : 0;
            $('#overall-progress-bar').css('width', `${Math.min(overallProgress, 100)}%`).text(`${Math.round(overallProgress)}%`);

            if (completedBatches === totalBatches && overallProgress === 100) {
                clearInterval(progressUpdateInterval);
                this.toggleButtons(ProgressPhase.GENERATE_CREDENTIALS, true);
            }
        };

        const displayNations = (batch: PrintLettersBatch) => {
            languageContainer.empty();
            batchProgressMap[batch.batch_id] = {totalItems: batch.items, processedItems: 0};

            const sortedCountries = batch.nations.sort((a, b) => a.nation.localeCompare(b.nation));
            sortedCountries.forEach((country, i) => {
                const rowDiv = $('<div>').addClass('row-container-nations');

                const progressBarContainer = $(`
                <div class="loading-bar-container">
                    <div class="nation-name">${country.nation}</div>
                    <div class="progress-counter">0/${country.items}</div>
                    <div class="loading-bar">
                        <div class="progress" id="progressGC_${i}">
                            0%
                        </div>
                    </div>
                </div>
            `);

                rowDiv.append(progressBarContainer);
                languageContainer.append(rowDiv);

                let progress = 0;
                let processedItems = 0;
                const totalDuration = country.items * 10;
                const intervalDuration = totalDuration / 100;

                const interval = setInterval(() => {
                    if (progress < 100) {
                        progress += 1;
                        const newProcessedItems = Math.round((progress / 100) * country.items);

                        progressBarContainer.find('.progress').css('width', `${progress}%`).text(`${progress}%`);
                        progressBarContainer.find('.progress-counter').text(`${newProcessedItems}/${country.items}`);

                        batchProgressMap[batch.batch_id].processedItems += (newProcessedItems - processedItems);
                        processedItems = newProcessedItems;
                        updateBatchCounter(batch.batch_id);
                    } else {
                        clearInterval(interval);
                    }
                }, intervalDuration);
            });
        };

        const handleBatchSelection = (batchDiv: JQuery<HTMLElement>, batch: PrintLettersBatch) => {
            progressContainer.find('.batch-item').removeClass('selected');
            batchDiv.addClass('selected');
            displayNations(batch);
        };

        // Handle the case where we have to aggregate the batches by languages and selected countries
        const aggregation: { [lang: string]: { lang: number, countries: CountriesOfBatch[], items: number } } = {};
        this.filteredBatch.forEach(batch => {
            const selectedCountries = this.selectedCountriesByBatch[batch.batch_id] || [];
            batch.nations.forEach(country => {
                if (selectedCountries.includes(country.nation_id)) {
                    if (aggregation[batch.batch_id]) {
                        aggregation[batch.batch_id].countries.push(country);
                        aggregation[batch.batch_id].items += country.items;
                    } else {
                        aggregation[batch.batch_id] = {
                            lang: country.nation_id,
                            countries: [country],
                            items: country.items
                        };
                    }
                }
            });
        });

        const selected: GenerateCretentialBatches = [];
        Object.entries(aggregation).forEach(([batchId, countries]) => {
            selected.push({
                batch_id: Number(batchId),
                nation_ids: countries.countries.map(c => c.nation_id)
            });
        });

        // const selected = this.ConvertPrintLettersBatchToGenerateCretentialBatches();

        if (this.state.phase === ProgressPhase.SELECT_BATCHES_NATIONS) this.client.begin_unique_keys(selected).done(() => {
            this.client.generate_unique_keys();
            this.updateLoop(() => {
            });
        })/*.fail(() => {
            setTimeout(() => {
                this.resetAll();
            }, 10000);
        })*/

        if (this.state.phase === ProgressPhase.GENERATE_CREDENTIALS_DISPLAY_START) {
            this.client.generate_unique_keys()
            this.updateLoop(() => {
            });
        }

        // Start updating the progress at regular intervals
        const progressUpdateInterval = setInterval(updateBatchProgress, 1000);

        // Attach click events for batch selection after rendering
        this.filteredBatch.forEach((batch, index) => {
            const batchDiv = $('<div>').addClass('batch-item').prop('id', batch.batch_id);

            const batchProgressBarContainer = $(`
            <div class="loading-bar-container">
                <div class="batch-name">${batch.name}</div>
                <div class="progress-counter">0/${batch.items}</div>
                    <div class="loading-bar">
                    <div class="progress" id="progressBatch_${index}">0%</div>
                    </div>
                </div>
            `);

            batchDiv.append(batchProgressBarContainer);
            progressContainer.append(batchDiv);

            batchDiv.on('click', () => {
                handleBatchSelection(batchDiv, batch);
            });
            if (index === 0) handleBatchSelection(batchDiv, batch);
        });
    }

    // private ConvertPrintLettersBatchToGenerateCretentialBatches():GenerateCretentialBatches {
    //
    //     // Handle the case where we have to aggregate the batches by languages and selected countries
    //     const aggregation: { [lang: string]: { lang: number, countries: CountriesOfBatch[], items: number } } = {};
    //     this.filteredBatch.forEach(batch => {
    //         const selectedCountries = this.selectedCountriesByBatch[batch.batch_id] || [];
    //         batch.nations.forEach(country => {
    //             if (selectedCountries.includes(country.nation_id)) {
    //                 if (aggregation[batch.batch_id]) {
    //                     aggregation[batch.batch_id].countries.push(country);
    //                     aggregation[batch.batch_id].items += country.items;
    //                 } else {
    //                     aggregation[batch.batch_id] = {
    //                         lang: country.nation_id,
    //                         countries: [country],
    //                         items: country.items
    //                     };
    //                 }
    //             }
    //         });
    //     });
    //
    //     const selected: GenerateCretentialBatches = [];
    //     Object.entries(aggregation).forEach(([batchId, countries]) => {
    //         selected.push({
    //             batch_id: Number(batchId),
    //             nation_ids: countries.countries.map(c => c.nation_id)
    //         });
    //     });
    //
    //     return selected;
    // }

    private GeneratePDF() {
        if (this.state.state === ServerProgressStateType.PDF_GENERATION) {
            this.updateLoop(() => {
            });
        } else if (this.state.state === ServerProgressStateType.UNIQUE_KEY_GENERATION_DONE) {
            this.client.begin_print_pdf().done((x) => {
                this.client.print_pdf();
                this.updateLoop(() => {
                });
            })
        }
    }

    private Summary() {
        this.client.get_report().done((x) => {
            const response:GetReport = x;

            const ResultContainer = $('#print-letters-results-container');
            ResultContainer.empty();

            const resultTemplate = $(DataLayer.templates["print-letters-results-ul-template"])

            // Map the response data to the results template
            resultTemplate.find('[data-content-type="print-letters-results-generated-pdf"]').text(response.generated_pdf);
            resultTemplate.find('[data-content-type="print-letters-results-total-letters"]').text(response.total_letters);
            resultTemplate.find('[data-content-type="print-letters-results-import-sum"]').text(response.import_sum);
            resultTemplate.find('[data-content-type="print-letters-results-total-items"]').text(response.total_items);

            ResultContainer.append(resultTemplate); // Append the results to the container

            if (response.errors && response.errors.length > 0) {
                const ErrorContainer = $('#print-letters-errors-container');
                ErrorContainer.empty(); // Clear any existing error messages

                for (const error of response.errors) {
                    const errorTemplate = $(DataLayer.templates["print-letters-errors-ul-template"])

                    // Set the error descriptions and codes
                    errorTemplate.find('[data-content-type="print-letters-error-description"]').text(error.description);
                    errorTemplate.find('[data-content-type="print-letters-error-code"]').text(error.code);

                    ErrorContainer.append(errorTemplate); // Append each error to the container
                }
            }
        });
    }

    private getPrintaleBatches() {
        this.client.get_printable_batches().done(x => {
            this.batches = x;
            this.selectBatchAndNation();
        });
    }

    /**
     def update_eater() -> None:
     tasks = {}
     progress = Progress(expand=False, auto_refresh=False)
     processed = []
     progress.start()

     total = {}
     for i in get_all()["result"]:
     if i["nation_id"] not in total:
     total[i["nation_id"]] = {
     "total_no": i["total_no"],
     "nation_name": i["nation_name"],
     "nation_id": i["nation_id"],
     "items": {
     i["print_id"]: i["processed_no"]
     },
     "totals": {
     i["print_id"]: i["total_no"]
     },
     "errors": {i["print_id"]} if str(i["progress_state"])[-1] == "3" else set()
     }
     else:
     total[i["nation_id"]]["items"][i["print_id"]] = i["processed_no"]
     total[i["nation_id"]]["totals"][i["print_id"]] = i["total_no"]

     if str(i["progress_state"])[-1] == "3":
     total[i["nation_id"]]["errors"].add(i["print_id"])

     format_item = lambda x, p, t: f"Print {x['nation_name']:<20}: {p:>5}/{t:>5}"
     for i in total.values():
     processed_no = sum(i["items"].values())
     total_no = sum(i["totals"].values())
     tasks[i["nation_id"]] = progress.add_task(format_item(i, processed_no, total_no), completed=processed_no, total=total_no)
     progress.refresh()

     while check_pending(total):
     updates = get_updates(processed)
     if not updates["result"]:  # se non ho aggiornamenti
     if not updates["success"]:
     print(updates)
     processed = []
     continue

     processed = [{"print_id": i["print_id"], "updates_no": i["updates_no"]} for i in updates["result"]]

     for i in updates["result"]:
     total[i["nation_id"]]["items"][i["print_id"]] = i["processed_no"]

     if str(i["progress_state"])[-1] == "3":
     total[i["nation_id"]]["errors"].add(i["print_id"])

     for i in total.values():
     task = tasks[i["nation_id"]]
     processed_no = sum(i["items"].values())
     current = progress.tasks[task].completed
     total_c = progress.tasks[task].total
     progress.update(task,
     description=format_item(i, processed_no, total_c),
     advance=processed_no - current)

     progress.refresh()
     time.sleep(0.1)

     while get_async_task_status()["result"]["running"]:
     time.sleep(0.3)

     progress.stop()
     * */

    private update_interval: ReturnType<typeof setInterval>;

    private updateLoop(on_completed: () => void) {

        if (this.update_interval !== void 0)
            clearInterval(this.update_interval);

        const get_updates_options = getDefaultRequestOptions()
        get_updates_options.show_loading = false;

        type progress_t = {
            progress_bar: JQuery,
            nation_name: string,
            nation_id: number,
            updates: { [print_id: number]: Update },
            errors: number[]
        }
        type total_t = { [nation_id: number]: progress_t }
        const total: total_t = {}
        // TODO clear progress bar container
        const update_progress = (total: total_t) => {
            for (const progress of Object.values(total)) {
                const tot = Object.values(progress.updates).reduce((stack, update) => stack + update.total_no, 0)
                const comp = Object.values(progress.updates).reduce((stack, update) => stack + update.processed_no, 0)
                const percentage = tot / comp * 100;

                if (progress.errors)
                    console.log("Partially fault updates!"); // TODO handle this

                progress.progress_bar.css("width", `${percentage}%`).text(`${percentage.toFixed(2)}%`);
                progress.progress_bar.find(".progress-counter").text(`${comp}/${tot}`);
            }
        }

        const check_pending = (total: total_t) => {
            let c = 0;

            for (const update of Object.values(total)) {
                let effective_total_no = Object.values(update.updates).reduce((s, u) => s + u.total_no, 0)
                const comp = Object.values(update.updates).reduce((stack, update) => stack + update.processed_no, 0)

                for (const print_id of update.errors)
                    effective_total_no -= update.updates[print_id].total_no + update.updates[print_id].processed_no;

                if (effective_total_no !== comp)
                    c++;
            }

            return c;
        }

        this.client.get_all().done(
            updates => {
                for (const update of updates) {
                    const nation_id = update.nation_id;
                    if (!(nation_id in total)) {
                        const progress = $();
                        progress.append() // TODO
                        total[nation_id] = {
                            nation_name: update.nation_name,
                            nation_id: nation_id,
                            progress_bar: progress,
                            updates: {},
                            errors: []
                        }
                    }

                    total[nation_id].updates[update.print_id] = update;
                    if (update.is_fault())
                        total[nation_id].errors.push(update.print_id)
                }

                update_progress(total);

                let processed: inputGetUpdates = [];
                let tmp = this.update_interval = setInterval(() => {
                    if (!check_pending(total)) {
                        this.client.get_async_task_status().done(
                            s => {
                                if (!s.running) {
                                    on_completed();
                                    clearInterval(tmp);
                                }
                            }
                        )
                        return;
                    }

                    this.client.get_updates(processed, get_updates_options).done(
                        updates => {
                            if (!updates) {
                                processed = [];
                                return;
                            }

                            processed = [];
                            for (const update of updates) {
                                processed.push({
                                    print_id: update.print_id,
                                    updates_no: update.updates_no
                                });

                                const progress = total[update.print_id];
                                progress.updates[update.print_id] = update;

                                if (update.is_fault())
                                    progress.errors.push(update.print_id);
                            }

                            update_progress(total);
                        }
                    )

                }, 750)
            }
        )
    }

    private showStep(step: ProgressPhase) {
        const steps = $('.progress-bar .step');
        const contents = $('.step-content');

        if (step === ProgressPhase.SELECT_BATCHES_NATIONS) {
            $(".step-content").removeClass("active");
            $(".progress-bar .step").removeClass("active");

            const idx = Number(this.selected_batches);
            $(steps[idx]).addClass("active");
            $(contents[idx]).addClass("active");
        } else {
            if (step === ProgressPhase.GENERATE_CREDENTIALS_DISPLAY_START) {
                step += 2;
            } else {
                step += 1;
            }
            steps.removeClass("completed");
            $(steps[1]).removeClass("active");
            $(contents[1]).removeClass("active");
            $(steps[step - 1]).removeClass("active");
            $(contents[step - 1]).removeClass("active");
            $(steps[step]).addClass("completed").removeClass("active").last().addClass("active");

            $(contents.removeClass("active")[step]).addClass("active");
        }
    }

    private draw_page(phase: ProgressPhase) {
        this.toggleButtons(phase);
        this.updateNextStepButtonState();

        this.showStep(phase);

        switch (phase) {
            case ProgressPhase.SELECT_BATCHES_NATIONS:
                this.toggleButtons(ProgressPhase.SELECT_BATCHES_NATIONS);
                !this.selected_batches ? this.getPrintaleBatches() : this.showLanguageSelector()
                break;
            case ProgressPhase.GENERATE_CREDENTIALS_DISPLAY_START:
                this.GenerateCredentialsStartButtonDisplay();
                break;
            case ProgressPhase.GENERATE_CREDENTIALS:
                if (this.state.state === ServerProgressStateType.UNIQUE_KEY_GENERATION_DONE) {
                    this.showStep(ProgressPhase.GENERATE_PDF);
                    this.GeneratePDF();
                } else {
                    this.GenerateCredentials()
                }
                break;
            case ProgressPhase.GENERATE_PDF:
                if (this.state.state === ServerProgressStateType.PDF_GENERATION_DONE) {
                    this.showStep(ProgressPhase.SUMMARY);
                    this.Summary();
                }
                this.GeneratePDF();
                break;
            case ProgressPhase.SUMMARY:
                this.Summary();
                break;
            default:
                this.throwError(phase);
                break;
        }
    }

    private throwError(phase: ProgressPhase) {
        const errormsg = "Errore del sistema, provare a ricaricare la pagina; se il problema persiste contattare l'assistenza"
        console.error(errormsg, phase);
        alert(errormsg);
        throw new Error(errormsg + " Phase:" + phase);
    }

    protected OnPrintLetters(): void {
        this.reattach()
    }
}