import {DataLayer} from "./DataLayer";
import Enumerable from "linq";
import $ from "jquery";
import {B64File} from "../Api/Types";

type order_type_t = "str" | "number" | "parse_number" | "date";
type order_mode_t = "asc" | "desc" | "off";
type search_mode_t = "full" | "partial";

type filter_t = {
    attr: string,
    order_type: order_type_t,
    mode: order_mode_t
    search_mode: search_mode_t
}

type prop_set<T> = {
    [arg0 in keyof T]?: string;
};

export class UtilsLayer extends DataLayer {
    protected disable_css = {
        'background-color': '#ccc',
        'color': '#666',
        'cursor': 'not-allowed',
        'border': '1px solid #aaa',
    };

    protected enable_next_css = {
        'background-color': '#28a745',
        'color': '#fff',
        'cursor': 'pointer',
        'border': '1px solid #007bff'
    };

    protected enable_back_css = {
        'background-color': '#dc3545',
        'color': '#fff',
        'cursor': 'pointer',
        'border': '1px solid #007bff'
    };

    protected formatDateData(date: Date): string {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
        const day = String(date.getDate()).padStart(2, '0');

        // 3. Combine to get the desired format
        return `${year}-${month}-${day}`;
    }

    protected UpdateSearch<T extends Object>(group: T[],
                                             search_bar_box: JQuery,
                                             filter_source: JQuery,
                                             extra_input: JQuery): Enumerable.IEnumerable<T> {
        if (group.length === 0)
            return Enumerable.from([]);

        let filters: filter_t[] = [];
        let off_filters = 0;

        let must_contains = search_bar_box.find("input").val().toString().toLowerCase();
        for (const x of filter_source.find("i")) {
            const order_mode = x.getAttribute("data-filter-status");
            const search_mode = x.getAttribute("data-search-mode") ?? "partial";
            if (order_mode === void 0)
                return Enumerable.from([]);

            if (order_mode === "off")
                ++off_filters;

            filters.push({
                attr: x.getAttribute("data-filter-attr"),
                mode: order_mode as order_mode_t,
                order_type: x.getAttribute("data-filter-order-type") as order_type_t,
                search_mode: search_mode as search_mode_t
            })
        }

        const filter_val = (x: T, filter: filter_t, date_as_string: boolean = false) => {
            // @ts-ignore
            const val: string | Date | number = x[filter.attr];
            if (val === null)
                return null;

            if (filter.order_type === "date")
                return date_as_string ? (<Date>val).toLocaleString() : (<Date>val).getTime();
            else if (["str", "number"].includes(filter.order_type))
                return val;
            else
                return Number((<string>val).match(/\d+/).toString());
        }

        const search_results = search_bar_box.find(".researched").hide();

        const extra_data_filer: prop_set<T> = {};

        for (let e of extra_input) {
            const i = $(e);
            const ctx = {a: i.attr("data-extra-search-arg") as string, v: i.val() as string};

            const k = group[0];
            if (!(ctx.a in k)) {
                console.warn("Ignoring", e);
                continue;
            }

            if (ctx.v === "sp:any" || !ctx.v)
                continue;

            if (ctx.v === "sp:0r")
                return Enumerable.from([]);

            extra_data_filer[ctx.a as keyof T] = ctx.v;
        }

        let counter: JQuery = void 0;
        if (must_contains !== "" || extra_data_filer)
            counter = search_results.show().find('[data-content-type="found"]').text(0);

        let c = 0;
        let sorted_group = Enumerable.from(group);
        if (extra_data_filer) {
            sorted_group = sorted_group.where(
                x => {
                    for (let item in extra_data_filer) {
                        if (x[item] != extra_data_filer[item])
                            return false;
                    }

                    if (must_contains === "")
                        counter?.text(++c);

                    return true;
                }
            );
        }

        if (must_contains !== "")
            sorted_group = sorted_group.where(
                x => {
                    for (const filter of filters) {
                        const val = <string | number>filter_val(x, filter, true);
                        // @ts-ignore
                        x["__cached_filter" + filter.attr] = val;

                        if (val === null)
                            continue;
                        const string_val = val.toString().toLowerCase();

                        if ((filter.search_mode === "full" && string_val === must_contains) ||
                            (filter.search_mode === "partial" && string_val.includes(must_contains))) {
                            counter?.text(++c);
                            return true;
                        }
                    }
                    return false;
                }
            )

        if (filters.length - off_filters === 0)
            return sorted_group;

        let first = true;
        for (const filter of filters) {
            if (filter.mode === "off")
                continue;

            if (first) {
                sorted_group = sorted_group.orderBy(x => filter_val(x, filter));
                first = false;
            } else {
                // @ts-ignore
                sorted_group = (<Enumerable.IOrderedEnumerable<T>>sorted_group).thenBy(x => x["__cached_filter" + filter.attr] ?? filter_val(x, filter));
            }

            // @ts-ignore
            sorted_group.descending = filter.mode === "desc";
        }

        return sorted_group;
    }

    protected setSearchEvents<T>(selector: string,
                                 filter_s: string,
                                 source_getter: () => T[],
                                 on_update: (search: Enumerable.IEnumerable<T>) => void,
                                 extra_input?: JQuery): () => void {
        const filter_source = $(filter_s);
        const search_bar_box = $(selector);

        extra_input ??= $();

        const update = (element: T[]) => on_update(this.UpdateSearch(element, search_bar_box, filter_source, extra_input));
        let x: NodeJS.Timeout = null;

        filter_source.on("click", "div", e => {
            const i = $(e.currentTarget).find("i");
            const seq = ["off", "asc", "desc"];
            const pos = (seq.indexOf(i.attr("data-filter-status")) + 1) % 3;
            filter_source.find("[data-filter-status!=off]").attr("data-filter-status", "off")
            i.attr("data-filter-status", seq[pos]);

            update(source_getter())

            if (x !== null)
                clearTimeout(x);
        });

        let last_search: string = null;

        search_bar_box.find<HTMLInputElement>("input").on("input", e => {
            const elements = source_getter();

            if (x !== null)
                clearTimeout(x);


            if (elements.length < 500 ||
                (last_search !== null && Math.abs(e.currentTarget.value.length - last_search.length) !== 1)
            ) {
                x = null;
                setTimeout(() => update(elements), 0);
                last_search = e.currentTarget.value;
                return;
            }
            last_search = e.currentTarget.value;

            x = setTimeout(() => {
                x = null;
                update(elements);
            }, 400);
        });

        return () => update(source_getter());
    }

    protected setUrlParameter(name: string, value: string) {
        this.url_params.set(name, value);
        window.history.replaceState(null, null, "?" + this.url_params.toString());
    }

    protected formatCurrency(value: number): string {
        return new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'EUR',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        }).format(value).replace('€', '').trim();
    };

    protected HandleDownload(file: B64File) {
        const url = URL.createObjectURL(new Blob([file.b64], {type: file.mime_type}));
        const link = document.createElement("a");
        link.href = url;
        link.download = file.file_name;
        link.click();
    }
}