import $ from "jquery";
import {
    ApiError,
    BaseResponse,
    User,
    EmptyResponse,
    GenericPayload,
    LoginSuccess,
    B64File,
    RequestOptions,
    getDefaultRequestOptions,
    BaseResponseObject,
    BatchesTypes,
    ManageBatch,
    LoadData,
    PrintLettersBatch,
    GenerateCretentialBatches,
    Update,
    inputGetUpdates,
    BeginReattach,
    GetAsyncTaskStatus,
    GetReport,
} from "./Types";
import {Popup} from "../Popups/Popup";

export interface ErrorWrappedDeferred<TR, TJ = any, TN = any> extends JQuery.Deferred<TR, TJ, TN> {
    error_wrapped_done(doneCallback: JQuery.TypeOrArray<JQuery.Deferred.Callback<TR>>,
                       ...doneCallbacks: Array<JQuery.TypeOrArray<JQuery.Deferred.Callback<TR>>>): this;
}

export class SmartPriseApiClient {
    public static BASE_ENDPOINT: string;
    public static RENEW_ENDPOINT: string;
    public static LOGIN: string;

    private static instance: SmartPriseApiClient;


    public static readonly ErrorMap: Record<number, string> = {
        "-7": "Mail non valida",
        // 20: "La password deve contenere tra gli 8 e i 32 caratteri, almeno una lettera maiuscola, una minuscola e un carattere tra [#?!@$%^&*-]",
        20: "Credenziali non valide",
        21: "Password troppo lunga",
        22: "Password troppo corta",
        41: "Invio email fallito"
    }

    private constructor() {
        let paths = (window.location.origin + window.location.pathname).split("/");
        paths = paths.slice(0, paths.length - 1);
        paths[paths.length - 1] = "rest/api.php";
        SmartPriseApiClient.BASE_ENDPOINT = paths.join("/");
        paths[paths.length - 1] = "refresh/index.php";
        SmartPriseApiClient.RENEW_ENDPOINT = paths.join("/");

        SmartPriseApiClient.LOGIN = window.location.origin + window.location.pathname + "?page=login";
    }

    public static getInstance(): SmartPriseApiClient {
        if (!SmartPriseApiClient.instance)
            SmartPriseApiClient.instance = new SmartPriseApiClient();

        return SmartPriseApiClient.instance;
    }

    private _EndApiCall<ST extends BaseResponseObject | BaseResponseObject[], FT extends ApiError>
    (request_options: RequestOptions,
     _deferred: JQuery.Deferred<ST, undefined | string | BaseResponse<ST, FT>>
    ): ErrorWrappedDeferred<ST, undefined | string | BaseResponse<ST, FT>> {

        type response_t = BaseResponse<ST, FT>;
        const deferred = _deferred as ErrorWrappedDeferred<ST, undefined | string | response_t>;

        if (request_options.show_loading) {
            setTimeout(() => {
                const control = $(`<div class="loading-wp"><div><img src="${request_options.loading_image}" alt=""></div></div>`);
                control.prependTo(request_options.show_loading_on);
                deferred.always(() => control.remove())
            }, 1000);
        }

        if (request_options.default_eh)
            deferred.fail(ctx => {
                let err_code: number = void 0;
                if (typeof ctx !== "string") {
                    if (ctx === void 0)
                        ctx = "Errore sconosciuto"
                    else {
                        err_code = ctx.error.code;
                        ctx = ctx.error.code in SmartPriseApiClient.ErrorMap ? SmartPriseApiClient.ErrorMap[ctx.error.code] : ctx.error.message;
                    }
                }

                Popup.DefaultErrorInject(<string>ctx).popup.SetShowCloseCross();

                if (err_code >= 10 && err_code < 20) {
                    setTimeout(() => {
                        window.location.assign(SmartPriseApiClient.LOGIN);
                    }, 7_500)
                }
            });

        deferred.error_wrapped_done = (doneCallback: JQuery.Deferred.Callback<ST>,
                                       ...doneCallbacks: JQuery.Deferred.Callback<ST>[]) => {
            const wp = (f: JQuery.Deferred.Callback<ST>) => {

                return (...args: any[]) => {
                    try {
                        f(...args);
                    } catch (e) {
                        console.error(e);
                        console.log(f)
                    }
                }
            }
            deferred.done(wp(doneCallback), ...doneCallbacks.map(wp));
            return deferred;
        }

        return deferred;
    }

    private _ProcessApiCallResponse<ST extends BaseResponseObject | BaseResponseObject[] = EmptyResponse, FT extends ApiError = ApiError>
    (params: GenericPayload,
     method: string,
     resolver: (content: object) => ST,
     request: () => JQuery.jqXHR<BaseResponse<ST, FT>>,
     request_options: RequestOptions,
     wraps: JQuery.Deferred<ST, string | undefined | BaseResponse<ST, FT>> = null,
    ): JQuery.Deferred<ST, string | undefined | BaseResponse<ST, FT>> {
        type d_type = string | BaseResponse<ST, FT>;
        const deferred = wraps ?? $.Deferred<ST, d_type>();

        deferred.fail(e => (e))

        request().then(
            (data: BaseResponse<ST, FT>) => {
                if (!data.success) {
                    deferred.reject(data);
                    return;
                }

                try {
                    deferred.resolve(resolver(data.result));
                } catch (e) {
                    deferred.reject(`Invalid response from ${method}`);
                    console.error(e);
                }
            }, e => {
                const js = e.responseJSON as BaseResponse<ST, FT> | undefined;

                if (e.status == 401 && request_options.fail_handle_session_handle && js !== void 0) {
                    if (js.error.code === 12) { // ACCESS_TOKEN_EXPIRED
                        this._RenewToken()
                            .done(() => this._ProcessApiCallResponse(params, method, resolver, request, request_options, wraps))
                            .fail(() => document.location = SmartPriseApiClient.LOGIN);
                        return;
                    } else if (js.error.code === 13 || js.error.code === 14 || js.error.code === 15) { // REFRESH_TOKEN_EXPIRED|USER_DISABLED|USER_INVALID_CREDENTIALS
                        document.location = SmartPriseApiClient.LOGIN
                        return;
                    }
                }

                deferred.reject(js);
            });

        return deferred;
    }

    private _RenewToken(): JQuery.jqXHR {
        return $.get(SmartPriseApiClient.RENEW_ENDPOINT, {is_rest: true});
    }

    private _ApiCall<ST extends BaseResponseObject | BaseResponseObject[] = EmptyResponse>(data: GenericPayload,
                                                                                           resolver: (content: object) => ST,
                                                                                           method: string,
                                                                                           request_options: RequestOptions) {
        const url = SmartPriseApiClient.BASE_ENDPOINT + "?method=" + method;
        const request = () => $.post(url, JSON.stringify(data), 'json');

        request_options ??= getDefaultRequestOptions();
        const deferred = this._ProcessApiCallResponse(data, method, resolver, request, request_options);

        return this._EndApiCall(request_options, deferred)
    }

    private ApiCall<ST extends BaseResponseObject = EmptyResponse>(type: { new(v: object): ST },
                                                                   params: GenericPayload,
                                                                   method: string,
                                                                   request_options: RequestOptions) {
        return this._ApiCall(params,
            (content: object) => new type(content),
            method,
            request_options);
    }

    private ArrayApiCall<ST extends BaseResponseObject = EmptyResponse>(type: { new(v: object): ST },
                                                                        params: GenericPayload,
                                                                        method: string,
                                                                        request_options: RequestOptions) {
        return this._ApiCall(params,
            (content: object[]): ST[] => content.map(x => new type(x)),
            method,
            request_options);
    }

    /** AUTH */

    public Login(email: string,
                 password: string,
                 request_options?: RequestOptions) {
        return this.ApiCall(LoginSuccess,
            {
                email: email,
                password: password
            },
            "rest\\auth\\login", request_options)
    }

    public log_out(request_options?: RequestOptions) {
        return this.ApiCall(EmptyResponse, {}, "rest\\auth\\logout", request_options)
    }

    /** END AUTH */

    /** SYNC */

    public reset_all(
        request_options?: RequestOptions
    ) {
        return this.ApiCall(EmptyResponse, {},
            "rest\\sync\\reset_all", request_options)
    }

    public begin_reattach(
        request_options?: RequestOptions
    ) {
        return this.ApiCall(BeginReattach, {},
            "rest\\sync\\begin_reattach", request_options)
    }

    public get_updates(updates?: inputGetUpdates,
                       request_options?: RequestOptions) {
        return this.ArrayApiCall(Update, {updates: updates},
            "rest\\sync\\get_updates", request_options)
    }

    public get_all(updates?: inputGetUpdates,
                   request_options?: RequestOptions) {
        return this.ArrayApiCall(Update, {updates: updates},
            "rest\\sync\\get_all", request_options)
    }

    public get_async_task_status(request_options?: RequestOptions) {
        return this.ApiCall(GetAsyncTaskStatus, {},
            "rest\\sync\\get_async_task_status", request_options)
    }

    /** END SYNC */

    /** MANAGE BATCHES */
    public get_batches(
        request_options?: RequestOptions) {
        return this.ArrayApiCall(ManageBatch,
            {},
            "rest\\manage_batches\\get_batches", request_options)
    }

    public drop_batch(
        id: number | string,
        request_options?: RequestOptions
    ) {
        return this.ApiCall(EmptyResponse, {
                drop_id: id,
            },
            "rest\\manage_batches\\drop_batch", request_options)
    }

    /** END MANAGE BATCHES */

    /** PRINT LETTERS */
    public begin_unique_keys(selected: GenerateCretentialBatches, requst_optins?: RequestOptions) {
        return this.ArrayApiCall(Update, {selected_items: selected}, "rest\\print_letters\\begin_unique_keys", requst_optins)
    }

    public get_printable_batches(request_options?: RequestOptions) {
        return this.ArrayApiCall(PrintLettersBatch, {}, "rest\\print_letters\\get_printable_batches", request_options)
    }

    public generate_unique_keys(request_options?: RequestOptions) {
        return this.ApiCall(EmptyResponse, {},
            "rest\\print_letters\\generate_unique_keys", request_options)
    }

    public begin_print_pdf(request_options?: RequestOptions) {
        return this.ArrayApiCall(Update, {}, "rest\\print_letters\\begin_print_pdf", request_options);
    }

    public print_pdf(request_options?: RequestOptions) {
        return this.ApiCall(Update, {}, "rest\\print_letters\\print_pdf", request_options)
    }

    public get_selected_batches(request_options?: RequestOptions) {
        return this.ArrayApiCall(PrintLettersBatch, {}, "rest\\print_letters\\get_printing_batches", request_options);
    }

    public get_report(request_options?: RequestOptions) {
        return this.ApiCall(GetReport, {}, "rest\\print_letters\\get_report", request_options)
    }

    public download_zip(request_options?: RequestOptions) {
        return this.ApiCall(B64File, {}, "rest\\print_letters\\download_zip", request_options)
    }

    public close_batch(request_options?: RequestOptions) {
        return this.ApiCall(EmptyResponse, {}, "rest\\print_letters\\close_batch", request_options);
    }

    /** END PRINT LETTERS*/

    /** LOAD DATA */
    public upload_payments(
        name: string,
        year: number,
        month: number,
        csv: string,
        request_options?: RequestOptions
    ) {
        return this.ApiCall(LoadData, {
                csv: csv,
                month: month,
                year: year,
                name: name,
                allow_cache: true,
            },
            "rest\\load_data\\upload_payments", request_options)
    }

    public upload_batch(
        csv: string,
        batch_type: BatchesTypes,
        name: string,
        request_options?: RequestOptions
    ) {
        return this.ApiCall(LoadData, {
                batch_type: batch_type,
                name: name,
                csv: csv,
                allow_cache: true,
            },
            "rest\\load_data\\upload", request_options)
    }

}
