import {JSONObject, array, custom, optional, required, union} from 'ts-json-object'
import {ErrorWrappedDeferred, SmartPriseApiClient} from "./Client";
import $ from "jquery";


export class BaseResponseObject extends JSONObject {
    public cached_dom: JQuery = null;

    public domCacheLookUp(getter: () => JQuery): JQuery {
        if (this.cached_dom === null)
            return this.cached_dom = getter();
        return this.cached_dom;
    }
}

export class LoginSuccess extends BaseResponseObject {
    @required
    public readonly page: string
}

export class User extends BaseResponseObject {
    @required
    public readonly id: number

    @required
    public readonly email: string

    @required
    public readonly first_name: string

    @required
    public readonly last_name: string

}

export class B64File extends BaseResponseObject {
    @required
    @custom((_, __, value) => {
        const bytes = atob(value.toString());
        const raw = new Array(bytes.length);
        for (let i = 0; i < bytes.length; i++)
            raw[i] = bytes.charCodeAt(i);
        return new Uint8Array(raw);
    })
    b64: Uint8Array

    @required
    file_name: string

    @required
    mime_type: string
}

export class EmptyResponse extends BaseResponseObject {
}

export class RetryAfterErrorData extends BaseResponseObject {
    @required
    public readonly retry_after: Date
}

export interface ApiError<T = {}> {
    message: string,
    code: number,
    data: T
}

export interface BaseResponse<ResultT extends BaseResponseObject | BaseResponseObject[] = EmptyResponse,
    ErrT extends ApiError = ApiError> {
    success: boolean,
    error: ErrT,
    result: ResultT
}

export interface GenericPayload {

    [x: string]: unknown;
}

export type RequestOptions = {
    show_loading: boolean,
    show_loading_on: JQuery,
    default_eh: boolean,
    fail_handle_session_handle: boolean,
    loading_image: string
}

export enum BatchesTypes {
    PRINTABLE,
    WORKED
}

export enum NumberOfItemOnPage {
    LOW = 10,
    MEDIUM = 25,
    HIGH = 50,
    VERY_HIGH = 100,
    EXTREME = 200
}

/*export enum PrintLettersSteps {
    SELECT_BATCHES = 1,
    SELECT_NATIONS,
    GENERATE_CREDENTIALS,
    GENERATE_PDF,
    SUMMARY,
}*/

export class GetAsyncTaskStatus extends BaseResponseObject {
    @required
    running: boolean
}

export enum ServerProgressStateType {
    NONE = -1,
    PREPARED = 0,

    UNIQUE_KEY_GENERATION = 11,
    UNIQUE_KEY_GENERATION_DONE = 13,
    UNIQUE_KEY_GENERATION_ERROR = 12,

    PDF_GENERATION = 21,
    PDF_GENERATION_DONE = 23,
    PDF_GENERATION_ERROR = 22
}

export enum ProgressPhase {
    SELECT_BATCHES_NATIONS = -1,

    GENERATE_CREDENTIALS_DISPLAY_START = 0,
    GENERATE_CREDENTIALS = 1,

    GENERATE_PDF = 2,

    SUMMARY = 3
}

export class PrintError extends BaseResponseObject {
    @required
    public readonly print_work_error_id: number

    @required
    public readonly code: number

    @required
    public readonly description: string

    @required
    public readonly ref_print_work_id: number | null
}

export class GetReport extends BaseResponseObject {
    @required
    public readonly total_items: number;

    @required
    public readonly import_sum: number;

    @required
    public readonly total_letters: number;

    @required
    public readonly generated_pdf: number;

    @array(PrintError)
    @required
    public readonly errors: PrintError[];
}

export class EffectiveProgressState {
    public constructor(
        public readonly state: ServerProgressStateType,
        public readonly phase: ProgressPhase,
    ) {
    }
}

export class BeginReattach extends BaseResponseObject {
    @required
    @custom((_, __, value) => ServerProgressStateType[value.toString().toUpperCase()])
    public readonly progress_state: ServerProgressStateType;

    @required
    public readonly reprinting: boolean;

    private cached_effective_state: EffectiveProgressState = null;

    public get effective_state(): EffectiveProgressState {
        if (this.cached_effective_state == null) {
            let state: ProgressPhase;
            const n = Number(typeof this.progress_state === "string" ? ServerProgressStateType[this.progress_state] : this.progress_state) as ServerProgressStateType;

            if (n === ServerProgressStateType.NONE) {
                state = ProgressPhase.SELECT_BATCHES_NATIONS;
            } else if (n === ServerProgressStateType.PREPARED) {
                state = ProgressPhase.GENERATE_CREDENTIALS_DISPLAY_START; // In questo caso aggiungi un pulsante per far partire le stampe
            } else {
                let tmp = n.toString();
                state = Number(tmp.slice(0, tmp.length - 1)) as ProgressPhase;
            }
            this.cached_effective_state = new EffectiveProgressState(n, state);
        }

        return this.cached_effective_state;
    }
}

export class ManageBatch extends BaseResponseObject {
    @required
    public readonly id: number;

    @required
    public readonly name: string;

    @required
    public readonly creation_date: Date;

    @required
    public readonly items: number;

    @required
    public readonly printed: number;

    @required
    public readonly paid: number;

    @required
    public readonly drop_id: number;

    @required
    @custom((_, __, value) => BatchesTypes[value.toString().toUpperCase()])
    public type: BatchesTypes
}

export class CountriesOfBatch extends BaseResponseObject {
    @required
    public readonly nation: string;

    @required
    public readonly nation_id: number;

    @required
    public readonly items: number;
}

export type GenerateCretentialBatches = Array<{
    batch_id: number;
    nation_ids: number[];
}>;

export type inputGetUpdates = {
    print_id: number,
    updates_no: number
}[];

export class Update extends BaseResponseObject {
    @required
    public readonly print_id: number;

    @required
    public readonly nation_id: number;

    @required
    public readonly nation_name: string;

    @required
    public readonly updates_no: number;

    @required
    public readonly processed_no: number;

    @required
    public readonly total_no: number;

    @required
    @custom((_, __, value) => ServerProgressStateType[value.toString().toUpperCase()])
    public readonly progress_state: ServerProgressStateType;

    public is_fault(): boolean {
        return this.progress_state.toString()[0] === "2";
    }
}

export class PrintLettersBatch extends BaseResponseObject {
    @required
    public readonly batch_id: number;

    @required
    public readonly upload_date: Date;

    @required
    public readonly name: string;

    @required
    public readonly items: number;

    @array(CountriesOfBatch)
    @required
    public readonly nations: CountriesOfBatch[];

}

export class LoadData extends BaseResponseObject {
    @required
    public readonly rows: number
}

export function getDefaultRequestOptions(): RequestOptions {
    return {
        show_loading: true,
        show_loading_on: $(document.body),
        default_eh: true,
        fail_handle_session_handle: true,
        loading_image: "resources/images/loading.gif"
    }
}

