import $ from "jquery";
import {DataLayer} from "./Layers/DataLayer";
import {Popup} from "./Popups/Popup";
import {LoadDataLayer} from "./Layers/LoadDataLayer";

export class SmartPrise extends LoadDataLayer {
    public RegisterEvents() {
        for (const element of $(".template").detach().removeClass("template")) {
            const t = $(element);
            DataLayer.templates[t.attr("id")] = t.removeAttr("id")[0].outerHTML;
        }

        $('#log-out').on('click', () => {
            this.client.log_out();
        });

        DataLayer.meta = $("#meta").detach();
        this.url_params = new URLSearchParams(window.location.search);

        const page = this.GetPage();

        if (page === "loaddata")
            this.OnLoadData();
        else if (page === "managebatches")
            this.OnManageBatches();
        else if (page === "printletters")
            this.OnPrintLetters();
        else if (page === "closeprintingbatch")
            this.onClosePrintingBatch();
        else if (page === "exportpayments")
            this.OnExportPayments();
        else if (page === "sentmailreports")
            this.OnSentMailReports()
        else if (page === "paymentsreports")
            this.OnPaymentsReports()
        else if (page === "login")
            this.OnLogin();
    }
}

// @ts-ignore
globalThis.smartprice = new SmartPrise();
// @ts-ignore
globalThis.Popup = Popup;
// @ts-ignore
globalThis.TS$ = $;

// @ts-ignore
$(() => globalThis.smartprice.RegisterEvents());