import $ from "jquery";
import {DataLayer} from "./DataLayer";
import {ExportPaymentsLayer} from "./ExportPaymentsLayer";

export class CloseBatchLayer extends ExportPaymentsLayer {
    currentStepCB = 1;
    private sampleBatchesCloseBatch = [
        {name: "Batch 1", date: "2023-06-25", type: "plate", items: 100},
        {name: "Batch 2", date: "2023-06-26", type: "tenants", items: 150},
        {name: "Batch 3", date: "2023-06-26", type: "plate", items: 200},
        {name: "Batch 4", date: "2023-06-26", type: "tenants", items: 150},
        {name: "Batch 5", date: "2023-06-26", type: "tenants", items: 350},
        {name: "Batch 6", date: "2023-06-26", type: "plate", items: 150},
        {name: "Batch 7", date: "2023-06-26", type: "tenants", items: 150},
        {name: "Batch 8", date: "2023-06-26", type: "tenants", items: 128},
        {name: "Batch 9", date: "2023-06-26", type: "tenants", items: 150},
        {name: "Batch 10", date: "2023-06-26", type: "plate", items: 75},
        {name: "Batch 11", date: "2023-06-25", type: "plate", items: 100},
        {name: "Batch 12", date: "2023-06-26", type: "tenants", items: 150},
        {name: "Batch 13", date: "2023-06-26", type: "plate", items: 200},
        {name: "Batch 14", date: "2023-06-26", type: "tenants", items: 150},
        {name: "Batch 15", date: "2023-06-26", type: "tenants", items: 350},
        {name: "Batch 16", date: "2023-06-26", type: "plate", items: 150},
        {name: "Batch 17", date: "2023-06-26", type: "tenants", items: 150},
        {name: "Batch 18", date: "2023-06-26", type: "tenants", items: 128},
        {name: "Batch 19", date: "2023-06-26", type: "tenants", items: 150},
        {name: "Batch 20", date: "2023-06-26", type: "plate", items: 75},
        {name: "Batch 21", date: "2023-06-26", type: "tenants", items: 38},
        {name: "Batch 22", date: "2023-06-26", type: "plate", items: 75},
        {name: "Batch 23", date: "2023-06-25", type: "plate", items: 100},
        {name: "Batch 24", date: "2023-06-26", type: "tenants", items: 150},
        {name: "Batch 25", date: "2023-06-26", type: "plate", items: 200},
        {name: "Batch 26", date: "2023-06-26", type: "tenants", items: 150},
        {name: "Batch 27", date: "2023-06-26", type: "tenants", items: 350},
        {name: "Batch 28", date: "2023-06-26", type: "plate", items: 150},
        {name: "Batch 29", date: "2023-06-26", type: "tenants", items: 150},
        {name: "Batch 30", date: "2023-06-26", type: "tenants", items: 128},
        {name: "Batch 31", date: "2023-06-26", type: "tenants", items: 150},
        {name: "Batch 32", date: "2023-06-26", type: "plate", items: 75},
        {name: "Batch 33", date: "2023-06-26", type: "plate", items: 75},
        {name: "Batch 34", date: "2023-06-26", type: "plate", items: 75},
        {name: "Batch 35", date: "2023-06-26", type: "plate", items: 75},
        {name: "Batch 36", date: "2023-06-26", type: "plate", items: 75},
        {name: "Batch 37", date: "2023-06-26", type: "plate", items: 75},
        {name: "Batch 38", date: "2023-06-26", type: "plate", items: 75},
        {name: "Batch 39", date: "2023-06-26", type: "plate", items: 75},
        {name: "Batch 40", date: "2023-06-26", type: "plate", items: 75},
        {name: "Batch 41", date: "2023-06-26", type: "tenants", items: 150},
        {name: "Batch 42", date: "2023-06-26", type: "plate", items: 75},
        {name: "Batch 43", date: "2023-06-26", type: "plate", items: 75},
        {name: "Batch 44", date: "2023-06-26", type: "plate", items: 75},
        {name: "Batch 45", date: "2023-06-26", type: "plate", items: 75},
        {name: "Batch 46", date: "2023-06-26", type: "plate", items: 75},
        {name: "Batch 47", date: "2023-06-26", type: "plate", items: 75},
        {name: "Batch 48", date: "2023-06-26", type: "plate", items: 75},
        {name: "Batch 49", date: "2023-06-26", type: "plate", items: 75},
        {name: "Batch 50", date: "2023-06-26", type: "plate", items: 75},
        {name: "Batch 51", date: "2023-06-26", type: "plate", items: 75},
        {name: "Batch 52", date: "2023-06-26", type: "plate", items: 75},
        {name: "Batch 53", date: "2023-06-26", type: "plate", items: 75},
        {name: "Batch 54", date: "2023-06-26", type: "plate", items: 75},
        {name: "Batch 55", date: "2023-06-26", type: "plate", items: 75},
        {name: "Batch 56", date: "2023-06-26", type: "plate", items: 75},
        {name: "Batch 57", date: "2023-06-26", type: "plate", items: 75},
        {name: "Batch 58", date: "2023-06-26", type: "plate", items: 75},
        {name: "Batch 59", date: "2023-06-26", type: "plate", items: 75},
        {name: "Batch 60", date: "2023-06-26", type: "plate", items: 75},
        {name: "Batch 61", date: "2023-06-26", type: "plate", items: 75},
        {name: "Batch 62", date: "2023-06-26", type: "plate", items: 75},
        {name: "Batch 63", date: "2023-06-26", type: "plate", items: 75},
        {name: "Batch 64", date: "2023-06-26", type: "plate", items: 75},
        {name: "Batch 65", date: "2023-06-26", type: "plate", items: 75},
        {name: "Batch 66", date: "2023-06-26", type: "plate", items: 75},
        {name: "Batch 67", date: "2023-06-26", type: "plate", items: 75},
        {name: "Batch 68", date: "2023-06-26", type: "plate", items: 75},
        {name: "Batch 69", date: "2023-06-26", type: "plate", items: 75},
        {name: "Batch 70", date: "2023-06-26", type: "plate", items: 75},
        {name: "Batch 71", date: "2023-06-26", type: "plate", items: 75},
        {name: "Batch 72", date: "2023-06-26", type: "plate", items: 75},
        {name: "Batch 73", date: "2023-06-26", type: "plate", items: 75},
        {name: "Batch 74", date: "2023-06-26", type: "plate", items: 75},
        {name: "Batch 75", date: "2023-06-26", type: "plate", items: 75},
        {name: "Batch 76", date: "2023-06-26", type: "plate", items: 75},
        {name: "Batch 77", date: "2023-06-26", type: "plate", items: 75},
        {name: "Batch 78", date: "2023-06-26", type: "plate", items: 75},
        {name: "Batch 79", date: "2023-06-26", type: "plate", items: 75},
        {name: "Batch 80", date: "2023-06-26", type: "plate", items: 75},
        {name: "Batch 81", date: "2023-06-26", type: "plate", items: 75},
        {name: "Batch 82", date: "2023-06-26", type: "plate", items: 75},
        {name: "Batch 83", date: "2023-06-26", type: "plate", items: 75},
        {name: "Batch 84", date: "2023-06-26", type: "plate", items: 75},
        {name: "Batch 85", date: "2023-06-26", type: "plate", items: 75},
        {name: "Batch 86", date: "2023-06-26", type: "plate", items: 75},
        {name: "Batch 87", date: "2023-06-26", type: "plate", items: 75},
        {name: "Batch 88", date: "2023-06-26", type: "plate", items: 75},
        {name: "Batch 89", date: "2023-06-26", type: "plate", items: 75},
        {name: "Batch 90", date: "2023-06-26", type: "plate", items: 75},
        {name: "Batch 91", date: "2023-06-26", type: "plate", items: 75},
        {name: "Batch 92", date: "2023-06-26", type: "plate", items: 75},
        {name: "Batch 93", date: "2023-06-26", type: "plate", items: 75},
        {name: "Batch 94", date: "2023-06-26", type: "plate", items: 75},
        {name: "Batch 95", date: "2023-06-26", type: "plate", items: 75},
        {name: "Batch 96", date: "2023-06-26", type: "plate", items: 75},
        {name: "Batch 97", date: "2023-06-26", type: "plate", items: 75},
        {name: "Batch 98", date: "2023-06-26", type: "plate", items: 75},
        {name: "Batch 99", date: "2023-06-26", type: "plate", items: 75},
        {name: "Batch 100", date: "2023-06-26", type: "plate", items: 75},
    ]

    private populateClosePrintingBatches(): void {
        const manageBatchesTBody = $('#close-printing-batches-row-batches-list');
        for (const e of this.sampleBatchesCloseBatch) {
            const row = $(DataLayer.templates["close-printing-batch-row-close-batch-template"]);
            row.find('[data-content-type="close-printing-batches-table-row-name"]').text(e.name);
            row.find('[data-content-type="close-printing-batches-table-row-date"]').text(e.date);
            row.find('[data-content-type="close-printing-batches-table-row-type"]').text(e.type);
            row.find('[data-content-type="close-printing-batches-table-row-items"]').text(e.items);
            manageBatchesTBody.append(row);
        }
    }

    private showStepCB(step: number): void {
        const $steps = $('.tabs .tab');
        const $contents = $('.tab-content');

        $steps.each((index, el) => {
            const $el = $(el);
            if (index < step - 1) {
                $el.addClass('completed');
            } else {
                $el.removeClass('completed');
            }
            $el.toggleClass('active', index === step - 1);
        });

        $contents.each((index, el) => {
            $(el).toggleClass('active', index === step - 1);
        });
    }

    private nextStepCB(): void {
        if (this.currentStepCB < 4) { // Changed to 4 for the summary step
            this.currentStepCB++;
            this.showStepCB(this.currentStepCB);
            if (this.currentStepCB === 2) {
                this.startProcessing('Creating Shipping Slip');
            } else if (this.currentStepCB === 3) {
                this.startProcessing('Sending Documents to Printer');
            }
        } else {
            this.showSummaryCB();
        }
    }

    private prevStepCB(): void {
        if (this.currentStepCB > 1) {
            this.currentStepCB--;
            this.showStepCB(this.currentStepCB);
        }
    }

    private startProcessing(processName: string): void {
        const $batches = $('input[name="batch"]:checked');
        const $progressContainer = $('#progressContainer');
        $progressContainer.empty();

        $batches.each((index, batch) => {
            const batchName = $(batch).val() as string;
            const $progressBarContainer = $(`
                <div class="loading-bar-container">
                    <div class="batch-name">${batchName}</div>
                    <div class="loading-bar">
                        <div class="progress" id="progress_${batchName}">0%</div>
                    </div>
                </div>
            `);
            $progressContainer.append($progressBarContainer);

            // Simulate progress
            let progress = 0;
            const interval = setInterval(() => {
                if (progress < 100) {
                    progress += 10;
                    $(`#progress_${batchName}`).css('width', `${progress}%`).text(`${progress}%`);
                } else {
                    clearInterval(interval);
                }
            }, 500);
        });
    }

    private toggleSelectAllCB(checked: boolean): void {
        $('input[name="batch"]').prop('checked', checked);
    }

    private showSummaryCB(): void {
        $('#summaryContent').html(`
            <h2>Summary Report</h2>
            <p><strong>Error/Exception Report:</strong></p>
            <ul>
                <li>Error: Failed to generate shipping slip for Batch 1</li>
                <li>Exception: Network issue during document sending for Batch 2</li>
            </ul>
            <p><strong>Aggregated Credits Result:</strong></p>
            <ul>
                <li>John Doe: $500</li>
                <li>Jane Smith: $750</li>
            </ul>
            <p><strong>Generated Report:</strong></p>
            <p>Download the generated report below.</p>
            <div class="button-container">
                <button class="download-button">Download Report</button>
                <button class="redo-button">Redo Process for Selected Batches</button>
            </div>
        `);
        this.showStepCB(4);
    }

    private initializeTabListeners(): void {
        // Attach click event listeners to tabs
        $('#nextStepCloseBatch').on('click', () => this.nextStepCB());
        $('#prevStepCloseBatch').on('click', () => this.prevStepCB());

        $('.tabs .tab').on('click', (event) => {
            const $clickedTab = $(event.currentTarget);
            const stepId = $clickedTab.attr('id');
            if (stepId) {
                this.showStepCB(Number(stepId));
            }
        });

        // Initialize select all checkbox
        $('#selectAll').on('change', (event) => {
            const isChecked = $(event.target).prop('checked');
            this.toggleSelectAllCB(isChecked);
        });
    }

    protected onClosePrintingBatch(): void {
        this.populateClosePrintingBatches();
        this.showStepCB(this.currentStepCB);
        this.initializeTabListeners();
    }
}
