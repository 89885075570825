import $ from "jquery";
import {DataLayer} from "./DataLayer";
import {LoginLayer} from "./LoginLayer";


export class PaymentsReportsLayer extends LoginLayer {
    private sampleBatchesPaymentsReports = [
        {id: "00001", date: "2023-06-25", items: 100,},
        {id: "00002", date: "2023-06-25", items: 100,},
        {id: "00003", date: "2023-06-25", items: 100,},
        {id: "00004", date: "2023-06-25", items: 100,},
        {id: "00005", date: "2023-06-25", items: 100,},
        {id: "00006", date: "2023-06-25", items: 100,},
        {id: "00007", date: "2023-06-25", items: 100,},
        {id: "00008", date: "2023-06-25", items: 100,},
        {id: "00009", date: "2023-06-25", items: 100,},
        {id: "00010", date: "2023-06-25", items: 100,},
        {id: "00011", date: "2023-06-25", items: 100,},
        {id: "00012", date: "2023-06-25", items: 100,},
        {id: "00013", date: "2023-06-25", items: 100,},
        {id: "00014", date: "2023-06-25", items: 100,},
        {id: "00015", date: "2023-06-25", items: 100,},
        {id: "00016", date: "2023-06-25", items: 100,},
        {id: "00017", date: "2023-06-25", items: 100,},
        {id: "00018", date: "2023-06-25", items: 100,},
        {id: "00019", date: "2023-06-25", items: 100,},
        {id: "00020", date: "2023-06-25", items: 100,},
        {id: "00021", date: "2023-06-25", items: 100,},
        {id: "00022", date: "2023-06-25", items: 100,},
        {id: "00023", date: "2023-06-25", items: 100,},
        {id: "00024", date: "2023-06-25", items: 100,},
        {id: "00025", date: "2023-06-25", items: 100,},
        {id: "00026", date: "2023-06-25", items: 100,},
        {id: "00027", date: "2023-06-25", items: 100,},
        {id: "00028", date: "2023-06-25", items: 100,},
        {id: "00029", date: "2023-06-25", items: 100,},
        {id: "00030", date: "2023-06-25", items: 100,},
        {id: "00031", date: "2023-06-25", items: 100,},
        {id: "00032", date: "2023-06-25", items: 100,},
        {id: "00033", date: "2023-06-25", items: 100,},
        {id: "00034", date: "2023-06-25", items: 100,},
        {id: "00035", date: "2023-06-25", items: 100,},
        {id: "00036", date: "2023-06-25", items: 100,},
        {id: "00037", date: "2023-06-25", items: 100,},
        {id: "00038", date: "2023-06-25", items: 100,},
        {id: "00039", date: "2023-06-25", items: 100,},
        {id: "00040", date: "2023-06-25", items: 100,},
        {id: "00041", date: "2023-06-25", items: 100,},
        {id: "00042", date: "2023-06-25", items: 100,},
        {id: "00043", date: "2023-06-25", items: 100,},
        {id: "00044", date: "2023-06-25", items: 100,},
        {id: "00045", date: "2023-06-25", items: 100,},
        {id: "00046", date: "2023-06-25", items: 100,},
        {id: "00047", date: "2023-06-25", items: 100,},
        {id: "00048", date: "2023-06-25", items: 100,},
        {id: "00049", date: "2023-06-25", items: 100,},
        {id: "00050", date: "2023-06-25", items: 100,},
        {id: "00051", date: "2023-06-25", items: 100,},
        {id: "00052", date: "2023-06-25", items: 100,},
        {id: "00053", date: "2023-06-25", items: 100,},
        {id: "00054", date: "2023-06-25", items: 100,},
        {id: "00055", date: "2023-06-25", items: 100,},
        {id: "00056", date: "2023-06-25", items: 100,},
        {id: "00057", date: "2023-06-25", items: 100,},
        {id: "00058", date: "2023-06-25", items: 100,},
        {id: "00059", date: "2023-06-25", items: 100,},
        {id: "00060", date: "2023-06-25", items: 100,},
        {id: "00061", date: "2023-06-25", items: 100,},
        {id: "00062", date: "2023-06-25", items: 100,},
        {id: "00063", date: "2023-06-25", items: 100,},
        {id: "00064", date: "2023-06-25", items: 100,},
        {id: "00065", date: "2023-06-25", items: 100,},
        {id: "00066", date: "2023-06-25", items: 100,},
        {id: "00067", date: "2023-06-25", items: 100,},
        {id: "00068", date: "2023-06-25", items: 100,},
        {id: "00069", date: "2023-06-25", items: 100,},
        {id: "00070", date: "2023-06-25", items: 100,},
        {id: "00071", date: "2023-06-25", items: 100,},
        {id: "00072", date: "2023-06-25", items: 100,},
        {id: "00073", date: "2023-06-25", items: 100,},
        {id: "00074", date: "2023-06-25", items: 100,},
        {id: "00075", date: "2023-06-25", items: 100,},
        {id: "00076", date: "2023-06-25", items: 100,},
        {id: "00077", date: "2023-06-25", items: 100,},
        {id: "00078", date: "2023-06-25", items: 100,},
        {id: "00079", date: "2023-06-25", items: 100,},
        {id: "00080", date: "2023-06-25", items: 100,},
        {id: "00081", date: "2023-06-25", items: 100,},
        {id: "00082", date: "2023-06-25", items: 100,},
        {id: "00083", date: "2023-06-25", items: 100,},
        {id: "00084", date: "2023-06-25", items: 100,},
        {id: "00085", date: "2023-06-25", items: 100,},
        {id: "00086", date: "2023-06-25", items: 100,},
        {id: "00087", date: "2023-06-25", items: 100,},
        {id: "00088", date: "2023-06-25", items: 100,},
        {id: "00089", date: "2023-06-25", items: 100,},
        {id: "00090", date: "2023-06-25", items: 100,},
        {id: "00091", date: "2023-06-25", items: 100,},
        {id: "00092", date: "2023-06-25", items: 100,},
        {id: "00093", date: "2023-06-25", items: 100,},
        {id: "00094", date: "2023-06-25", items: 100,},
        {id: "00095", date: "2023-06-25", items: 100,},
        {id: "00096", date: "2023-06-25", items: 100,},
        {id: "00097", date: "2023-06-25", items: 100,},
        {id: "00098", date: "2023-06-25", items: 100,},
        {id: "00099", date: "2023-06-25", items: 100,},
        {id: "00100", date: "2023-06-25", items: 100,},

    ]

    private populatePaymentsReprotsTable() {
        const payemntsReprotsTBody = $('#payments-reports-row-batch-list');
        for (const e of this.sampleBatchesPaymentsReports) {
            const row = $(DataLayer.templates["payments-reports-row-payments-reports-template"]);
            row.find('[data-content-type="payments-reports-table-row-id"]').text(e.id);
            row.find('[data-content-type="payments-reports-table-row-date"]').text(e.date);
            row.find('[data-content-type="payments-reports-table-row-items"]').text(e.items);
            row.find('[data-content-type="payments-reports-table-row-download"]').html('<button class="modify-button" id="showshowPopup_' + e.id + '">Download</button>');
            row.find('[data-content-type="payments-reports-table-row-details"]').html('<button class="modify-button" id="showshowPopup_' + e.id + '">Details</button>');
            payemntsReprotsTBody.append(row);
        }
    }

    public OnPaymentsReports() {
        this.populatePaymentsReprotsTable();
    }
}