import $ from "jquery";
import {ManageBatchesLayer} from "./ManageBatchesLayer";
import {BatchesTypes} from "../Api/Types";
import {DataLayer} from "./DataLayer";

export class LoadDataLayer extends ManageBatchesLayer {
    private Name: string = '';
    private Year: number = 0;
    private Month: number = 0;
    private File: string = ''; // Store file as Base64 string

    private namePrintable: string = '';
    private filePrintable: string = '';

    private nameWorked: string = '';
    private fileWorked: string = '';

    private checkMonth(): boolean {
        let paymentsMonthLoaded = $('#PaymentsMonth');
        let [year, month] = (<string>paymentsMonthLoaded.val()).split("-");

        let intYear = parseInt(year, 10);  // Convert year to an integer
        let intMonth = parseInt(month, 10); // Convert month to an integer (1 to 12)

        // Get the current date
        let currentDate = new Date();
        let currentYear = currentDate.getFullYear();
        let currentMonth = currentDate.getMonth() + 1; // getMonth() returns 0 for January, so add 1

        // Validate year and month
        if (Number.isNaN(intYear) || Number.isNaN(intMonth)) {
            return false;
        } else if (intYear < 2015) {
            return false;
        } else if (intYear > currentYear) {
            return false;
        } else if (intYear === currentYear && intMonth > currentMonth) {
            return false;
        } else {
            this.Year = intYear;
            this.Month = intMonth;
            return true;
        }
    }

    private checkName(nameInput: JQuery<HTMLElement>): boolean {
        const paymentName = nameInput.val() as string; // Get the value of the input field
        if (!(!paymentName || paymentName.trim() === "")) {
            this.Name = paymentName;
            return true;
        } else {
            return false;
        }
    }

    private async checkUploadedBatch(fileInput: JQuery<HTMLElement>): Promise<boolean> {
        // const fileInput = $('#printableBatchFile')[0] as HTMLInputElement;
        const fileI = fileInput[0] as HTMLInputElement;
        const file = fileI.files?.[0]; // Get the first file if uploaded

        if (file) {
            // Check if the file type is CSV
            const fileName = file.name; // Get the file name with extension
            const fileExtension = fileName.split('.').pop()?.toLowerCase(); // Get the file extension in lowercase

            if (fileExtension !== 'csv') {
                return false; // File is not a CSV
            }
            try {
                this.File = await this.prepareFile(file); // Convert file to Base64 and set File property
                return true; // Valid CSV file uploaded
            } catch (error) {
                return false;
            }
        } else {
            return false; // No file uploaded
        }
    }

    private async checkFilePayments(): Promise<boolean> {
        const fileInput = $('#PaymentsFile')[0] as HTMLInputElement; // Cast to HTMLInputElement
        const file = fileInput.files?.[0]; // Get the first file if uploaded

        if (file) {
            // Check if the file type is CSV
            const fileName = file.name; // Get the file name with extension
            const fileExtension = fileName.split('.').pop()?.toLowerCase(); // Get the file extension in lowercase

            if (fileExtension !== 'csv') {
                return false; // File is not a CSV
            }

            // Remove the extension from the file name
            const fileNameWithoutExtension = fileName.split('.').slice(0, -1).join('.');

            const paymentsInput = $('#Payments');
            if (!this.checkName(paymentsInput)) { // If the Payments input is empty
                paymentsInput.val(fileNameWithoutExtension); // Set the Payments input to the file name without extension
            }

            try {
                this.File = await this.prepareFile(file); // Convert file to Base64 and set File property
                return true; // Valid CSV file uploaded
            } catch (error) {
                return false;
            }
        } else {
            return false; // No file uploaded
        }
    }

    private prepareFile(file: File): Promise<string> {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();

            reader.onloadend = () => {
                const base64String = reader.result as string;
                const base64Data = base64String.split(',')[1];
                resolve(base64Data);
            };

            reader.onerror = (error) => {
                reject(error);
            };

            reader.readAsDataURL(file);
        });
    }

    private togglePrintableBatchButtonButton(button: JQuery<HTMLElement>) {
        if (this.filePrintable && this.checkName($('#printableBatchName'))) {
            button.css(this.enable_next_css).prop('disabled', false);
        } else {
            button.css(this.disable_css).prop('disabled', true);
        }
    }

    private toggleWorkedBatchButtonButton(button: JQuery<HTMLElement>) {
        if (this.fileWorked && this.checkName($('#workedBatchName'))) {
            button.css(this.enable_next_css).prop('disabled', false);
        } else {
            button.css(this.disable_css).prop('disabled', true);
        }
    }

    private toggleLoadPaymentsButton(button: JQuery<HTMLElement>) {
        if (this.checkMonth() && this.checkName($('#Payments')) && this.File) {
            button.css(this.enable_next_css).prop('disabled', false);
        } else {
            button.css(this.disable_css).prop('disabled', true);
        }
    }

    protected OnLoadData() {
        const loadPaymentsButton = $('#loadPayments');
        const printableBatchButton = $('#printableBatchButton');
        const workedBatchButton = $('#workedBatchButton');

        // Initial validation on page load
        this.toggleLoadPaymentsButton(loadPaymentsButton);
        this.toggleWorkedBatchButtonButton(workedBatchButton);
        this.togglePrintableBatchButtonButton(printableBatchButton);

        // Check month validity on input change
        $('#PaymentsMonth').on('change', () => {
            this.toggleLoadPaymentsButton(loadPaymentsButton);
        });

        // Check name validity on input (on every keystroke)
        $('#Payments').on('input', () => {
            this.toggleLoadPaymentsButton(loadPaymentsButton);
        });

        // Check file validity when a file is uploaded for payments
        $('#PaymentsFile').on('change', async () => {
            if (await this.checkFilePayments()) { // Check if a CSV file is uploaded and set the name if needed
                this.toggleLoadPaymentsButton(loadPaymentsButton); // Revalidate button
            }
        });

        // Handle printable batch file upload
        $('#printableBatchFile').on('change', async () => {
            const fileInput = $('#printableBatchFile');
            if (await this.checkUploadedBatch(fileInput)) {
                this.filePrintable = this.File; // Set the correct file variable
                this.togglePrintableBatchButtonButton(printableBatchButton); // Revalidate button
            }
        });

        // Handle worked batch file upload
        $('#workedBatchFile').on('change', async () => {
            const fileInput = $('#workedBatchFile');
            if (await this.checkUploadedBatch(fileInput)) {
                this.fileWorked = this.File; // Set the correct file variable
                this.toggleWorkedBatchButtonButton(workedBatchButton); // Revalidate button
            }
        });

        // Check name validity for printable batch
        $('#printableBatchName').on('input', () => {
            this.togglePrintableBatchButtonButton(printableBatchButton);
        });

        // Check name validity for worked batch
        $('#workedBatchName').on('input', () => {
            this.toggleWorkedBatchButtonButton(workedBatchButton);
        });


        // Handle printable batch submission
        printableBatchButton.on('click', async () => {
            const printableBatchName = $('#printableBatchName');
            if (this.checkName(printableBatchName) && this.filePrintable) {
                try {
                    this.namePrintable = printableBatchName.val() as string;
                    this.client.upload_batch(this.filePrintable, BatchesTypes.PRINTABLE, this.namePrintable).done((x) => {
                        const errorDataPrintable = $('#error-data-printable');
                        errorDataPrintable.empty();
                        const data = $(DataLayer.templates["error-data-printable-data"]);
                        data.find('[data-content-type="error-data-printable-data"]').text(x.rows);
                        errorDataPrintable.append(data);
                    });

                    // Clear fields on successful upload
                    printableBatchName.val('');
                    $('#printableBatchFile').val('');
                    this.filePrintable = ''; // Clear file variable
                    this.togglePrintableBatchButtonButton(printableBatchButton); // Revalidate button
                } catch (error) {
                }
            }
        });

        // Handle worked batch submission
        workedBatchButton.on('click', async () => {
            const workedBatchName = $('#workedBatchName');
            if (this.checkName(workedBatchName) && this.fileWorked) {
                try {
                    this.nameWorked = workedBatchName.val() as string;
                    this.client.upload_batch(this.fileWorked, BatchesTypes.WORKED, this.nameWorked).done((x) => {
                        const errorDataWorked = $('#error-data-worked');
                        errorDataWorked.empty();
                        const data = $(DataLayer.templates["error-data-worked-data"]);
                        data.find('[data-content-type="error-data-worked-data"]').text(x.rows)
                        errorDataWorked.append(data);
                    });

                    // Clear fields on successful upload
                    workedBatchName.val('');
                    $('#workedBatchFile').val('');
                    this.fileWorked = ''; // Clear file variable
                    this.toggleWorkedBatchButtonButton(workedBatchButton); // Revalidate button
                } catch (error) {
                }
            }
        });

        // Handle payments batch submission
        loadPaymentsButton.on('click', async () => {
            const Payments = $('#Payments')
            if (this.checkMonth() && this.checkName(Payments) && this.File) {
                try {
                    this.client.upload_payments(this.Name, this.Year, this.Month, this.File).done((x) => {
                        const errorDataPayments = $('#error-data-payments');
                        errorDataPayments.empty();
                        const data = $(DataLayer.templates["error-data-payments-data"]);
                        data.find('[data-content-type="error-data-payments-data"]').text(x.rows);
                        errorDataPayments.append(data);
                    });

                    // Clear fields on successful upload
                    $('#PaymentsMonth').val('');
                    Payments.val('');
                    $('#PaymentsFile').val('');
                    this.File = ''; // Clear file variable
                    this.toggleLoadPaymentsButton(loadPaymentsButton); // Revalidate button

                } catch (error) {
                    console.error('Error sending data:', error);
                }
            }
        });
    }
}