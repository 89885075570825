import {SmartPriseApiClient} from "../Api/Client";

export class DataLayer {
    public static meta: JQuery;
    public static templates: Record<string, string> = {};

    protected client: SmartPriseApiClient = SmartPriseApiClient.getInstance();
    protected url_params: URLSearchParams;

    public GetPage(): string {
        // @ts-ignore
        return globalThis.page ?? this.url_params.get("page");
    }

    public ToMySqlFormat(date: Date): string {
        return date.toISOString().slice(0, 19).replace('T', ' ');
    }
}