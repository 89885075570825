import {Popup} from "./Popup";
import {DataLayer} from "../Layers/DataLayer";
import $ from "jquery"

export class ConfirmPopup extends Popup {

    protected override BeforeInjecting() {
    }

    protected override AfterInjecting() {
        this.JSCenter();
    }

    protected override GetTemplate(): JQuery {
        return $(DataLayer.templates["generic-confirm-wrapper"]);
    }
}