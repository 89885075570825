import $ from 'jquery';
import {DataLayer} from "./DataLayer";
import {PrintLettersLayer} from "./PrintLettersLayer";
import {
    ManageBatch,
    BatchesTypes
} from "../Api/Types";

import {ConfirmPopup} from "../Popups/ConfirmPopup";

export class ManageBatchesLayer extends PrintLettersLayer {

    private delete(id: number) {
        this.client.drop_batch(id).done(x => {
            this.client.get_batches().then(x => {
                this.populateBatchTable(x);
            });
        });
    }

    private ConfirmPopup(name: string, id: number): void {
        const t = ConfirmPopup.DirectInject(null, 'Conferma', false, true);
        const tjq = t.popup.GetJQuery();
        t.popup.SetShowActions(false);

        const manageBatchesDelete = $('#confirm-wrapper');
        manageBatchesDelete.empty();
        const batchName = $(DataLayer.templates["generic-confirm-wrapper"]);
        batchName.find('[data-content-type="manage-batches-title-del"]').text(name);
        manageBatchesDelete.append(batchName);

        tjq.find('[data-content-type="manage-batches-title-del"]').text(name);
        // manageBatchesDelete.append(title);
        tjq.on("click", ".popup-act-ok", () => {
            this.delete(id)
            t.popup.Remove();
        });
        tjq.on("click", ".popup-act-cancel", () => {
            t.popup.Remove();
        });
    }

    private populateBatchTable(batches: ManageBatch[]): void {
        const manageBatchesTBody = $('#manage-batches-row-batch-list');

        manageBatchesTBody.empty();
        // Loop through the sample batches and populate the table
        for (const e of batches) {
            const row = $(DataLayer.templates["manage-batches-row-manage-baches-template"]);

            // Set the row content
            row.find('[data-content-type="manage-batches-table-row-name"]').text(e.name);
            row.find('[data-content-type="manage-batches-table-row-date"]').text(this.formatDateData(e.creation_date));
            row.find('[data-content-type="manage-batches-table-row-items"]').text(e.items);
            row.find('[data-content-type="manage-batches-table-row-printed"]').text(e.printed);
            row.find('[data-content-type="manage-batches-table-row-payed"]').text(e.paid);
            row.find('[data-content-type="manage-batches-table-row-type"]').text(e.type);

            // Set a unique data attribute on the delete button for identification
            row.find('[data-content-type="manage-batches-table-row-del-button"]').attr({
                'data-batch-name': e.name,  // Attach the batch name as a data attribute
                'data-batch-id': e.drop_id       // Attach the batch id as a data attribute
            });

            // Append the row to the table body
            manageBatchesTBody.append(row);
        }

        // Attach the delete functionality to all delete buttons
        manageBatchesTBody.on('click', '[data-content-type="manage-batches-table-row-del-button"]', (event) => {
            const button = $(event.currentTarget);
            const batchName = button.data('batch-name'); // Retrieve the batch name from the data attribute
            const batchId = button.data('batch-id'); // Retrieve the batch name from the data attribute
            this.ConfirmPopup(batchName, batchId);
        });
    }

    OnManageBatches(): void {
        this.client.get_batches().done(x => {
            this.populateBatchTable(x);
        });
    }
}
