import $ from 'jquery';
import {DataLayer} from "./DataLayer";
import {SentMailReportsLayer} from "./SentMailReportsLayer";

export class ExportPaymentsLayer extends SentMailReportsLayer {
    sampleExportPaymentes = [
        {id: "0000", date: "2023-06-25", items: 100},
        {id: "0001", date: "2023-06-25", items: 100},
        {id: "0002", date: "2023-06-25", items: 100},
        {id: "0003", date: "2023-06-25", items: 100},
        {id: "0004", date: "2023-06-25", items: 100},
        {id: "0005", date: "2023-06-25", items: 100},
        {id: "0006", date: "2023-06-25", items: 100},
        {id: "0007", date: "2023-06-25", items: 100},
        {id: "0008", date: "2023-06-25", items: 100},
        {id: "0009", date: "2023-06-25", items: 100},
        {id: "0010", date: "2023-06-25", items: 100},
        {id: "0011", date: "2023-06-25", items: 100},
        {id: "0012", date: "2023-06-25", items: 100},
        {id: "0013", date: "2023-06-25", items: 100},
        {id: "0014", date: "2023-06-25", items: 100},
        {id: "0015", date: "2023-06-25", items: 100},
        {id: "0016", date: "2023-06-25", items: 100},
        {id: "0017", date: "2023-06-25", items: 100},
        {id: "0018", date: "2023-06-25", items: 100},
        {id: "0019", date: "2023-06-25", items: 100},
        {id: "0020", date: "2023-06-25", items: 100},
        {id: "0021", date: "2023-06-25", items: 100},
        {id: "0022", date: "2023-06-25", items: 100},
        {id: "0023", date: "2023-06-25", items: 100},
        {id: "0024", date: "2023-06-25", items: 100},
        {id: "0025", date: "2023-06-25", items: 100},
        {id: "0026", date: "2023-06-25", items: 100},
        {id: "0027", date: "2023-06-25", items: 100},
        {id: "0028", date: "2023-06-25", items: 100},
        {id: "0029", date: "2023-06-25", items: 100},
        {id: "0030", date: "2023-06-25", items: 100},
        {id: "0031", date: "2023-06-25", items: 100},
        {id: "0032", date: "2023-06-25", items: 100},
        {id: "0033", date: "2023-06-25", items: 100},
        {id: "0034", date: "2023-06-25", items: 100},
        {id: "0035", date: "2023-06-25", items: 100},
        {id: "0036", date: "2023-06-25", items: 100},
        {id: "0037", date: "2023-06-25", items: 100},
        {id: "0038", date: "2023-06-25", items: 100},
        {id: "0039", date: "2023-06-25", items: 100},
        {id: "0040", date: "2023-06-25", items: 100},
        {id: "0041", date: "2023-06-25", items: 100},
        {id: "0042", date: "2023-06-25", items: 100},
        {id: "0043", date: "2023-06-25", items: 100},
        {id: "0044", date: "2023-06-25", items: 100},
        {id: "0045", date: "2023-06-25", items: 100},
        {id: "0046", date: "2023-06-25", items: 100},
        {id: "0047", date: "2023-06-25", items: 100},
        {id: "0048", date: "2023-06-25", items: 100},
        {id: "0049", date: "2023-06-25", items: 100},
        {id: "0050", date: "2023-06-25", items: 100},
        {id: "0051", date: "2023-06-25", items: 100},
        {id: "0052", date: "2023-06-25", items: 100},
        {id: "0053", date: "2023-06-25", items: 100},
        {id: "0054", date: "2023-06-25", items: 100},
        {id: "0055", date: "2023-06-25", items: 100},
        {id: "0056", date: "2023-06-25", items: 100},
        {id: "0057", date: "2023-06-25", items: 100},
        {id: "0058", date: "2023-06-25", items: 100},
        {id: "0059", date: "2023-06-25", items: 100},
        {id: "0060", date: "2023-06-25", items: 100},
        {id: "0061", date: "2023-06-25", items: 100},
        {id: "0062", date: "2023-06-25", items: 100},
        {id: "0063", date: "2023-06-25", items: 100},
        {id: "0064", date: "2023-06-25", items: 100},
        {id: "0065", date: "2023-06-25", items: 100},
        {id: "0066", date: "2023-06-25", items: 100},
        {id: "0067", date: "2023-06-25", items: 100},
        {id: "0068", date: "2023-06-25", items: 100},
        {id: "0069", date: "2023-06-25", items: 100},
        {id: "0070", date: "2023-06-25", items: 100},
        {id: "0071", date: "2023-06-25", items: 100},
        {id: "0072", date: "2023-06-25", items: 100},
        {id: "0073", date: "2023-06-25", items: 100},
        {id: "0074", date: "2023-06-25", items: 100},
        {id: "0075", date: "2023-06-25", items: 100},
        {id: "0076", date: "2023-06-25", items: 100},
        {id: "0077", date: "2023-06-25", items: 100},
        {id: "0078", date: "2023-06-25", items: 100},
        {id: "0079", date: "2023-06-25", items: 100},
        {id: "0080", date: "2023-06-25", items: 100},
        {id: "0081", date: "2023-06-25", items: 100},
        {id: "0082", date: "2023-06-25", items: 100},
        {id: "0083", date: "2023-06-25", items: 100},
        {id: "0084", date: "2023-06-25", items: 100},
        {id: "0085", date: "2023-06-25", items: 100},
        {id: "0086", date: "2023-06-25", items: 100},
        {id: "0087", date: "2023-06-25", items: 100},
        {id: "0088", date: "2023-06-25", items: 100},
        {id: "0089", date: "2023-06-25", items: 100},
        {id: "0090", date: "2023-06-25", items: 100},
        {id: "0091", date: "2023-06-25", items: 100},
        {id: "0092", date: "2023-06-25", items: 100},
        {id: "0093", date: "2023-06-25", items: 100},
        {id: "0094", date: "2023-06-25", items: 100},
        {id: "0095", date: "2023-06-25", items: 100},
        {id: "0096", date: "2023-06-25", items: 100},
        {id: "0097", date: "2023-06-25", items: 100},
        {id: "0098", date: "2023-06-25", items: 100},
        {id: "0099", date: "2023-06-25", items: 100},
        {id: "0100", date: "2023-06-25", items: 100},

    ]

    private populateExportPaymentsTable() {
        const manageBatchesTBody = $('#export-payments-row-batch-list');
        for (const e of this.sampleExportPaymentes) {
            const row = $(DataLayer.templates["export-payments-row-export-payments-template"]);
            row.find('[data-content-type="export-payments-table-row-id"]').text(e.id);
            row.find('[data-content-type="export-payments-table-row-date"]').text(e.date);
            row.find('[data-content-type="export-payments-table-row-items"]').text(e.items);
            // row.find('[data-content-type="export-payments-table-row-action"]')
            manageBatchesTBody.append(row);
        }
    }

    private filterPayments(): void {
        // Add your filter logic here
        // Example: update the item count and filter table rows based on the selected date range and definitive export checkbox
        const itemCount = $('#exportTableBody').find('tr').length;
        $('#itemCount').text(`${itemCount} items found`);
        alert("Filter functionality to be implemented");
    }

    protected OnExportPayments() {
        this.populateExportPaymentsTable();
        $('#filterPayments').on('click', () => {
            this.filterPayments();
        });
    }
}